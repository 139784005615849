import { useState, useEffect, useRef } from 'react';
import { Autocomplete, Typography, Grid, Stack, Box, Switch } from '@mui/joy';
import { Loader } from '@googlemaps/js-api-loader';
import Lottie from 'lottie-react';
import { format } from 'date-fns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import Location from './components/Location';
import world from './lottie/earth.json';

const googleMapsLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  version: 'beta',
  libraries: ['places']
});

function PlaceListApp() {
  const [places, setPlaces] = useState(() => {
    const storedPlaces = localStorage.getItem('places');
    return storedPlaces ? JSON.parse(storedPlaces) : [];
  });
  const [inputValue, setInputValue] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [customDateTime, setCustomDateTime] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const autocompleteServiceRef = useRef(null);

  useEffect(() => {
    googleMapsLoader.load().then(() => {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    });
  }, []);

  useEffect(() => {
    let intervalId;

    if (!customDateTime) {
      intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [customDateTime]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (!newInputValue.trim()) {
      setOptions([]);
      return;
    }

    if (autocompleteServiceRef.current) {
      setLoading(true);
      autocompleteServiceRef.current.getPlacePredictions(
        { input: newInputValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setOptions(
              predictions.map(prediction => ({
                id: prediction.place_id,
                label: prediction.description
              }))
            );
          } else {
            console.error('Autocomplete prediction error:', status);
            setOptions([]);
          }
          setLoading(false);
        }
      );
    }
  };

  const handleAddPlace = async (event, newValue) => {
    if (newValue && !places.some(place => place.id === newValue.id)) {
      setLoading(true);
      // Fetch timezone data
      const geocodeResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${newValue.id}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
      );
      const { results } = await geocodeResponse.json();
      const { lat, lng } = results[0].geometry.location;
      const timezoneResponse = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
          Date.now() / 1000
        )}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
      );
      const timezoneData = await timezoneResponse.json();

      const newPlace = {
        id: newValue.id,
        label: newValue.label,
        timeZoneId: timezoneData.timeZoneId,
        rawOffset: timezoneData.rawOffset,
        dstOffset: timezoneData.dstOffset
      };

      setPlaces(currentPlaces => {
        const updatedPlaces = [...currentPlaces, newPlace].sort(
          (a, b) => a.rawOffset + a.dstOffset - (b.rawOffset + b.dstOffset) // Sorting places based on total UTC offset
        );
        localStorage.setItem('places', JSON.stringify(updatedPlaces));
        return updatedPlaces;
      });
      setInputValue('');
      setLoading(false);
    }
  };

  const handleRemovePlace = placeId => {
    setPlaces(currentPlaces => {
      const updatedPlaces = currentPlaces.filter(place => place.id !== placeId);
      localStorage.setItem('places', JSON.stringify(updatedPlaces));
      return updatedPlaces;
    });
  };

  let dateTime;
  if (customDateTime) {
    dateTime = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={currentTime}
          onChange={newValue => setCurrentTime(newValue)}
          format="dd MMMM yyyy h:mmaaa"
        />
      </LocalizationProvider>
    );
  } else {
    dateTime = (
      <Typography level="body-lg">
        {format(currentTime, "do MMM yyyy 'at' h:mm:ssaaa")}
      </Typography>
    );
  }

  return (
    <Grid container justifyContent="center" my={3} pb={8}>
      <Grid xs={12}>
        <Stack gap={3} alignItems="center">
          <Typography level="h1" color="primary">
            Timezone Tracker
          </Typography>
          <Stack alignItems="center" gap={1}>
            {dateTime}
            <Switch
              checked={customDateTime}
              onChange={event => setCustomDateTime(event.target.checked)}
              startDecorator={'local time'}
              endDecorator={'set date & time'}
            />
          </Stack>
          <Autocomplete
            options={options}
            value={null}
            loading={loading}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleAddPlace}
            placeholder="Search and add places"
            sx={{ width: 300, marginBottom: 2 }}
          />
        </Stack>
      </Grid>
      {places.map((place, i) => (
        <Grid md={3} key={place.id} sx={{ width: '100%' }}>
          <Location
            place={place}
            targetTime={currentTime}
            removeLocation={handleRemovePlace}
          />
        </Grid>
      ))}
      <Grid xs={12} justifyContent="center" display="flex">
        <Box width={333} mt={11}>
          <Lottie animationData={world} loop={true} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PlaceListApp;
