import { Close } from '@mui/icons-material';
import { Stack, IconButton, Card, CardContent, Typography } from '@mui/joy';
import { differenceInHours } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

const Location = ({ place, targetTime, removeLocation }) => {
  const timeDifference = differenceInHours(
    fromZonedTime(targetTime, place.timeZoneId),
    targetTime
  );

  const displayTimeDifference = () => {
    if (timeDifference === 0) {
      return 'same time';
    }
    const hourLabel = Math.abs(timeDifference) === 1 ? 'hour' : 'hours';
    return `${Math.abs(timeDifference)} ${hourLabel} ${
      timeDifference > 0 ? 'behind' : 'ahead'
    }`;
  };

  return (
    <Card sx={{ my: 2, mx: 1 }}>
      <IconButton
        color="neutral"
        size="sm"
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => removeLocation(place.id)}
      >
        <Close sx={{ fontSize: 11 }} />
      </IconButton>
      <CardContent orientation="horizontal" sx={{ alignItems: 'center' }}>
        <Stack flex={1}>
          <Typography level="title-md">{place.label}</Typography>
          <Typography level="body-xs">{displayTimeDifference()}</Typography>
        </Stack>
        <Stack>
          <Typography level="body-lg">
            {formatInTimeZone(targetTime, place.timeZoneId, 'h:mmaaa')}
          </Typography>
          <Typography level="body-sm">
            {formatInTimeZone(targetTime, place.timeZoneId, 'do MMM yyyy')}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Location;
