import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CssBaseline, CssVarsProvider, extendTheme } from '@mui/joy';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));

const materialTheme = materialExtendTheme();

const theme = extendTheme({
  fontFamily: {
    display: 'Pacifico',
    body: 'Noto Sans JP'
  }
});

root.render(
  <React.StrictMode>
    <MaterialCssVarsProvider
      theme={{ [MATERIAL_THEME_ID]: materialTheme }}
      defaultMode="system"
    >
      <CssVarsProvider theme={theme} defaultMode="system">
        <CssBaseline enableColorScheme />
        <App />
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  </React.StrictMode>
);
